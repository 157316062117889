/* jshint unused: false */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// sass読み込み
require('@/assets/sass/app.scss')

// vee-validateの設定
import { ValidationProvider, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
Vue.component('ValidationProvider', ValidationProvider);
extend('required', required);
extend('required', {message: '入力してください。'});
extend('email', email);
extend('email', {message: 'メールアドレスの形式で入力してください。'});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
