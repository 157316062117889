import axios from "axios";
axios.defaults.withCredentials = false;

export default {
    namespaced: true,
    state: {
        token: ''
    },

    mutations: {
        create (state, data) {
            state.token = data;

            axios.get("/manage/login", {
                headers : {Authorization : "Bearer: "+data}
            });
        },

        admin_login(state, data){
            state.token = data;

            axios.get("/manage/login", {
                    headers : {Authorization : "Bearer: "+data}
                })
                .then(function(){
                    window.location.href = '/dashboard';
                });
        },

        destroy (state) {
            state.token = '';
        }
    },

    actions: {
        create ({ commit, dispatch }, data) {
            dispatch(
                'message/destroy',
                {},
                { root: true }
            )

            dispatch(
                'http/post',
                { url: '/owner/login', data, error: 'ログインに失敗しました。' },
                { root: true }
            ).then(res => {
                if(res.status == 200 && res.data!='error'){
                    commit('create', res.data)
                }else{
                    dispatch(
                        'message/create',
                        { error: 'ログインに失敗しました。'},
                        { root: true }
                    )
                }
            })
            .catch(err => {err})
        },


        admin_login({ commit }){
            let query = location.search
            axios.post('https://fconsult.xsrv.jp/api/owner/login/admin', {
                'token' : query.replace('?token=', ''),
            })
                .then(res =>{
                    if(res.status == 200 && res.data!='error'){
                        commit('admin_login', res.data)
                    }
                });
        },


        destroy ({ commit }) {
            commit('destroy')
        }
    }
}
