<template>
    <div class="container">
        <h2>OWNER'S WEB<span>オーナー様専用サイト</span></h2>

        <div>
            <div class="news_list">
                <p>2022/1/26よりログインIDが登録されている「メールアドレス」へと変更させていただいております。</p>
                <p>したがいまして、ID欄へはメールアドレスのご入力をお願いいたします。</p>
                <p>なおパスワードに変更はございません。</p>
                <p>今後ともよろしくお願いいたします。</p>
            </div>
        </div>

        <div id="login_err_message" v-show="errorMessage"><p>{{ errorMessage }}</p></div>

        <div class="formWrap">
            <div class="formItem">
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <input v-model="email" type="text" value="" placeholder="メールアドレス" name="email">
                    <p class="validate-err" v-show="errors[0]">{{ errors[0] }}</p>
                </ValidationProvider>
            </div>
            <div class="formItem">
              <ValidationProvider rules="required" v-slot="{ errors }">
                  <input v-model="password" type="password" placeholder="パスワード" name="password">
                  <p class="validate-err" v-show="errors[0]">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="submit_btn">
                <button type="button" class="btn loginBtn" @click="login()">ログイン</button>
            </div>

            <div class="password_forgot_text"><router-link to="/password_reset_mail">パスワードを忘れてしまった場合</router-link></div>
        </div>
    </div>
</template>

<script>
/* jshint unused: false */

export default {
  name: 'loginform-component',
  data(){
      return{
          'email': '',
          'password': '',
      }
  },

  methods: {
    login () {
      this.$store.dispatch(
        'auth/create',
        {
          'user': {
            email: this.email,
            password: this.password
          }
        }
      )
    },
  },

  computed: {
    isValidated () {
      return Object.keys(this.fields).every(k => this.fields[k].validated) &&
        Object.keys(this.fields).every(k => this.fields[k].valid)
    },
    token () {
      return this.$store.state.auth.token
    },
    errorMessage () {
      return this.$store.state.message.error
    }
  },
  created: function () {
    // already logined
    if (this.$store.state.auth.token) {
      this.$router.push('/dashboard',()=>{})
    }
  },
  watch: {
    token (newToken) {  // eslint-disable-line
      this.$router.push('/dashboard',()=>{})
    }
  }
}
</script>


<style scoped>
    .news_title{
        font-weight: 900;
        color:#fff;
        margin-bottom: 5px;
    }
    .news_list{
        list-style: none;
        padding: 1em 0.5em 1em 0.5em;
        background: rgba(255,255,255,0.5);
        border: solid 1px #eee;
        border-radius: 3px;
        color: #222;
        margin-bottom: 40px;
        line-height: 1.4;

    }
    .news_list li{
        position: relative;
        padding-left: 1em;
        cursor: pointer;
    }
    .news_list li::before{
        position: absolute;
        left: 4px;
        top: 4px;
        content: "";
        height: 5px;
        width: 5px;
        background: #222;
        border-radius: 50%;
    }

    #news_dialog_wrap{
        justify-content: center;
        align-items: center;
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.7);
        display: none;
    }
    #news_dialog_wrap.active{
        display: flex;
    }

    #news_dialog{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: inline-block;
        padding: 1em;
        background: #fff;
        border: solid 1px #ccc;
        line-height: 2;
        width: 100%;
        max-width: 640px;
        max-height: 70vh;
        overflow-y: scroll;
    }
</style>
