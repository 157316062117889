<template>
    <div id="login">
        <div class="flex">
            <div class="loginForm">
                <div>
                    <div id="logo"><router-link to="/"><img src="../assets/img/common/logo.png" alt="SOSOGoo! HOLDINGS"></router-link></div>

                    <div class="container">
                        <h2>Password Reset</h2>

                        <div id="login_err_message" v-show="errorMessage"><p>{{ errorMessage }}</p></div>

                        <div class="formWrap" v-if="!sendmail">
                            <div class="formWrap" style="color:#fff">
                                下記項目を入力して送信ボタンを押してください。
                                <br>そのあと、登録アドレスにパスワードを再設定するためのメールをお送りされますので
                                <br>再設定をお願いいたします。
                            </div>

                            <!--
                            <div class="formItem">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <input v-model="person_id" type="text" placeholder="オーナーID" name="person_id">
                                    <p class="validate-err" v-show="errors[0]">{{ errors[0] }}</p>
                                </ValidationProvider>
                            </div>
                            -->

                            <div class="formItem">
                              <ValidationProvider rules="required" v-slot="{ errors }">
                                  <input v-model="mailaddress" type="text" placeholder="メールアドレス" name="mailaddress">
                                  <p class="validate-err" v-show="errors[0]">{{ errors[0] }}</p>
                              </ValidationProvider>
                            </div>
                            <div class="submit_btn">
                                <button type="button" class="btn loginBtn" @click="login()">メールを送信する</button>
                            </div>
                        </div>


                        <div class="formWrap" v-if="sendmail" style="color:#fff">
                            メールを送信しました。
                            <br>メールに記載されている手順でパスワードをリセットしてください。
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div></div>
    </div>
</template>



<script>
// ajax用ライブラリ
import axios from "axios";
axios.defaults.withCredentials = true;

export default {
  data(){
      return{
          'person_id'   : '',
          'mailaddress' : '',
          'sendmail'    : false,
      }
  },


  methods: {
    login () {
        axios.post("https://fconsult.xsrv.jp/api/owner/password_reset_apply", {
        //axios.post("https://localhost/api/owner/password_reset_apply", {
            //person_id   : this.person_id,
            mailaddress : this.mailaddress,
        })
        .then((res) => {
            if( res.data==='mail_error' ){
                this.$store.state.message.error = 'メールアドレスに誤りがあります。';
            }

            if( res.data==='error' ){
                this.$store.state.message.error = 'パスワード変更処理に失敗しました。';
            }

            if( res.data==='success' ){
                this.sendmail = true;
            }
        })
        .catch((error) => console.log(error));
    }
  },


  computed: {
    isValidated () {
      return Object.keys(this.fields).every(k => this.fields[k].validated) &&
        Object.keys(this.fields).every(k => this.fields[k].valid)
    },
    errorMessage () {
      return this.$store.state.message.error
    }
  },


  created: function () {
    // already logined
    if (this.$store.state.auth.token) {
      this.$router.push('/dashboard',()=>{})
    }
  },
}
</script>
