<template>
  <div id="search" class="block">
    <h2>送金明細ダウンロード検索</h2>
    <div class="search-form formWrap">
      <div class="flex">
          <div class="flex" style="margin-right:2em;flex-wrap: nowrap;margin-bottom: 1em">
              <div class="search_title">物件名</div>

              <div class="">
                  <select name="build_id" id="build_id" v-model="build_id">
                    <option value="null" selected>全て</option>
                    <option
                      v-for="value in builds"
                      v-bind:key="value.id"
                      v-bind:value="value.id"
                    >
                      {{ value.label }}
                    </option>
                  </select>
              </div>
          </div>

          <div class="flex" style="margin-bottom: 1em">
              <div class="search_title">年月</div>

              <div class="">
                  <select name="year" id="year" v-model="year">
                    <option value="null" selected>全て</option>
                    <option
                      v-for="year in years"
                      v-bind:key="year"
                      v-bind:value="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                  年
                  <select name="month" id="month" v-model="month">
                  <option value="null">全て</option>
                    <option
                      v-for="month in months"
                      v-bind:key="month"
                      v-bind:value="month"
                    >
                      {{ month }}
                    </option>
                  </select>
                  月
              </div>
          </div>
      </div>

      <div class="flex">
          <div class="flex" style="margin-bottom: 1em">
              <div class="search_title">金融機関名</div>

              <select name="bank_id" id="bank_id" v-model="bank_id">
                <option value="null">全て</option>
                <option
                  v-for="value in banks"
                  v-bind:key="value.bank_id"
                  v-bind:value="value.bank_name"
                >
                  {{ value.bank_name }}
                </option>
              </select>
          </div>
      </div>

      <div class="submit_btn">
        <button type="button" class="btn loginBtn" @click="onSubmit()">
          検索する
        </button>
      </div>
    </div>

    <div class="block _sp_hidden">
      <table class="commonTbl">
        <tr>
          <th>送金年月</th>
          <th>物件名</th>
          <th>金融機関名</th>
          <th>金額</th>
          <th class="pdf" colspan="2">PDF</th>
        </tr>
        <tr v-for="value in remittances" v-bind:key="value.id">
          <td class="date">{{ value.remittance_on | moment }}</td>
          <td>{{ value.build_name }}</td>
          <td class="bank_name">{{ value.bank_name }}</td>
          <td class="price">{{ value.price | addComma }}円</td>
          <td class="pdf"><a href="" @click.prevent="openPDF(value.idnumber, value.build_name, value.remittance_on)">開く</a></td>
          <td class="pdf"><a href="" @click.prevent="downloadPDF(value.idnumber, value.build_name, value.remittance_on)">ダウンロード</a></td>
        </tr>
      </table>
    </div>

    <div class="block _sp_show">
        <table class="commonTbl">
            <tr>
              <th>送金年月</th>
              <th style="width:50%">物件名</th>
              <th>金額</th>
              <th class="pdf">PDF</th>
            </tr>

            <tr v-for="value in remittances" v-bind:key="value.id">
              <td class="date">{{ value.remittance_on | moment }}</td>
              <td>{{ value.build_name }}</td>
              <td class="price">{{ value.price | addComma }}円</td>
              <td class="pdf"><a href="" @click.prevent="downloadPDF(value.idnumber)">DL</a></td>
            </tr>
        </table>
    </div>
  </div>
</template>
<script>
/* jshint unused: false */

// ajax用ライブラリ
import axios from "axios";
axios.defaults.withCredentials = true;

// date format ライブラリ
import moment from "moment";

export default {
  name: "searchform-component",
  data() {
    return {
      build_id: null,
      year:(new Date()).getFullYear(),
      month:null,
      years: [],
      months: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
      ],
      bank_id: null,
      data: [],
      builds: [],
      remittances: [],
      banks: [],
    }
  },
  methods: {
    onSubmit() {
        //送金明細情報取得
        this.remittances = [];
        let query_strings = '?build_id='+this.build_id+'&bank_id='+this.bank_id+'&year='+this.year+'&month='+this.month;

        axios.get("https://fconsult.xsrv.jp/api/owner/transfers"+query_strings, {
            headers: {
              Authorization: "Bearer: "+this.$store.state.auth.token
            }
        })
        .then((res) => {
            if( res.data.length==0 ){
                alert('データが存在しません。');
                return;
            }
            for(let i in res.data){
                this.remittances.push({
                    'remittance_on': res.data[i].transfer_date,
                    'build_name': res.data[i].build_name,
                    'bank_name': res.data[i].bank_name,
                    'price': res.data[i].total_fee,
                    'idnumber': res.data[i].id
                });
            }
        })
        .catch((error) => console.log(error));
    },

    openPDF(idnumber, build_name, date){
        axios.get("/pdf/transfer.php?id=" + idnumber + "&flag=1", {
            responseType: "blob",
            headers: {
              Authorization: "Bearer: "+this.$store.state.auth.token
            }
        })
        .then((res) => {
            let userAgent = window.navigator.userAgent.toLowerCase();
            let file = new Blob([res.data], {type: 'application/pdf'});
            let url = URL.createObjectURL(file);

            if(userAgent.indexOf('trident')!=-1 || userAgent.indexOf('msie')!=-1 || userAgent.indexOf('edge')!=-1){
                console.log('ie');
                window.navigator.msSaveOrOpenBlob(file, "送金明細-"+build_name+"-"+moment(date).format('YYYY年MM月')+".pdf");
            }else{
                window.open(url);
            }
            return;
         })
        .catch((error) => console.log(error));
    },

    downloadPDF(idnumber, build_name, date){
      axios.get("/pdf/transfer.php?id=" + idnumber + "&flag=2", {
          responseType: "blob",
          headers: {
            Authorization: "Bearer: "+this.$store.state.auth.token
          }
      })
      .then((res) => {
          let userAgent = window.navigator.userAgent.toLowerCase();
          let file = new Blob([res.data], {type: 'application/pdf'});
          let url = URL.createObjectURL(file);

          if(userAgent.indexOf('trident')!=-1 || userAgent.indexOf('msie')!=-1 || userAgent.indexOf('edge')!=-1){
              console.log('ie');
              window.navigator.msSaveOrOpenBlob(file, "送金明細-"+build_name+"-"+moment(date).format('YYYY年MM月')+".pdf");
          }else{
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.download = "送金明細-"+build_name+"-"+moment(date).format('YYYY年MM月')+".pdf";
              a.href = url;
              a.click();
              a.remove();
              return;
          }
       })
      .catch((error) => console.log(error));
    }
  },
  created: function() {
    // 物件名のリストを作るよう
    axios.get("https://fconsult.xsrv.jp/api/owner/builds", {
        headers: {
          Authorization: "Bearer: "+this.$store.state.auth.token
        }
    })
    .then((res) => {
      for (var i in res.data) {
        this.builds.push({
            'id': res.data[i].id,
            'label': res.data[i].name
        });
      }
     })
    .catch((error) => console.log(error));


    // 年セレクトボックス生成用
    axios.get("https://fconsult.xsrv.jp/api/owner/year_range", {
          headers: {
            Authorization: "Bearer: "+this.$store.state.auth.token
          }
      })
      .then((res) => {
          for(let cnt = res.data.premier; cnt>=res.data.latest; cnt--){
              this.years.push(cnt);
          }
      })
      .catch((error) => console.log(error));


    //金融機関セレクトボックス生成用
    axios.get("https://fconsult.xsrv.jp/api/owner/banks", {
        headers: {
          Authorization: "Bearer: "+this.$store.state.auth.token
        }
    })
    .then((res) => {
        for(let i in res.data){
            this.banks.push({
                'bank_id': res.data[i].id,
                'bank_name': res.data[i].bank_name,
            });
        }
    })
    .catch((error) => console.log(error));


    //送金明細情報取得
    let now = new Date();
    axios.get("https://fconsult.xsrv.jp/api/owner/transfers?year="+now.getFullYear(), {
        headers: {
          Authorization: "Bearer: "+this.$store.state.auth.token
        }
    })
    .then((res) => {
        for(let i in res.data){
            this.remittances.push({
                'remittance_on': res.data[i].transfer_date,
                'build_name': res.data[i].build_name,
                'bank_name': res.data[i].bank_name,
                'price': res.data[i].total_fee,
                'idnumber': res.data[i].id
            });
        }
    })
    .catch((error) => console.log(error));
  },
  filters: {
    moment: function(date) {
      return moment(date).format("YYYY/MM");
    },
    addComma: function(val) {
      return val.toLocaleString();
    },
  },
};
</script>


<style scoped>
    .search-form .search_title{
        display: flex;
        align-items: center;
        border-left:5px solid #e5e5e5;
        padding:0 1em;
        font-weight: 900;
    }
</style>
