<template>
    <div id="login">
        <div class="flex">
            <div class="loginForm">
                <div>
                    <div id="logo"><a href="/"><img height="100" src="../assets/img/common/logo.png" alt="SOSOGoo! HOLDINGS"></a></div>
                    <loginform-component></loginform-component>
                </div>
            </div>
        </div>
        <div></div>
    </div>
</template>

<script>
import loginformComponent from '@/components/LoginformComponent'

export default {
  components: { loginformComponent },
  template: '<loginform-component></loginform-component>',
}

</script>
