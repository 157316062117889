/* jshint unused: false */

import axios from 'axios'
axios.defaults.withCredentials = false;

export default {
  namespaced: true,
  actions: {
    async request ({ dispatch, rootState }, { data, error }) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      headers['X-Requested-With'] = 'XMLHttpRequest'
      if (rootState.auth.token) {
        headers['Authorization'] = `Token ${rootState.auth.token}`
        headers['User-Id'] = rootState.auth.userId
      }

      if(data.user.email == "" || data.user.password == ""){
        dispatch(
          'message/create',
          { error: 'メールアドレスまたはパスワードを入力してください'},
          { root: true }
        )
        return;
      }

      const params = new URLSearchParams();
      params.append('email', data.user.email);
      params.append('password', data.user.password);
      return axios.post('https://fconsult.xsrv.jp/api/owner/login', params)
      //return axios.post('https://localhost/api/owner/login', params)
        .then(res =>{
            return res;
        })
        .catch(err => {
          dispatch(
            'message/create',
            { error: error, err },
            { root: true }
          )
        })
    },
    async post ({ dispatch }, requests) {
      //requests.method = 'post'
      return dispatch('request', requests)
    },
    async delete ({ dispatch }, requests) {
      requests.method = 'delete'
      return dispatch('request', requests)
    }
  }
}
