<template>
    <div id="download" class="customerPage">
        <h1>
            <div>
                送金明細一覧ダウンロード
                <span>LIST</span>
            </div>
        </h1>

        <div class="contents">
            <div class="block">
                <div id="search" class="block">
                    <h2>送金明細一覧ダウンロード 絞り込み</h2>

                    <div class="search-form formWrap">
                        <div class="flex">
                            <div class="flex" style="margin-right:2em;flex-wrap: nowrap;margin-bottom: 1em">
                                <div class="search_title">物件名</div>

                                <div class="">
                                    <select name="build_id" id="build_id" v-model="build_id">
                                        <option value="null" selected>全て</option>
                                        <option v-for="value in builds" v-bind:key="value.id" v-bind:value="value.id">{{ value.label }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="flex" style="margin-bottom: 1em">
                                <div class="search_title">期間</div>

                                <div class="flex">
                                    <div class="">
                                        <select name="from_year" id="from_year" v-model="from_year">
                                            <option value="null" selected>全て</option>
                                            <option v-for="year in years" v-bind:key="year" v-bind:value="year">{{ year }}</option>
                                        </select>
                                        年

                                        <select name="from_month" id="from_month" v-model="from_month">
                                            <option value="null" selected>全て</option>
                                            <option v-for="month in months" v-bind:key="month" v-bind:value="month">{{ month }}</option>
                                        </select>
                                        月から
                                    </div>

                                    <div class="">
                                        <select name="to_year" id="to_year" v-model="to_year">
                                            <option value="null" selected>全て</option>
                                            <option v-for="year in years" v-bind:key="year" v-bind:value="year">{{ year }}</option>
                                        </select>
                                        年

                                        <select name="to_month" id="to_month" v-model="to_month">
                                            <option value="null" selected>全て</option>
                                            <option v-for="month in months" v-bind:key="month" v-bind:value="month">{{ month }}</option>
                                        </select>
                                        月まで
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex">
                            <div class="flex" style="margin-bottom: 1em">
                                <div class="search_title">金融機関名</div>

                                <div class="">
                                    <select name="bank_id" id="bank_id" v-model="bank_id">
                                        <option value="null" selected>全て</option>
                                        <option v-for="value in banks" v-bind:key="value.bank_id" v-bind:value="value.bank_name">{{ value.bank_name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="submit_btn">
                            <button type="button" class="btn loginBtn" @click="onSubmit()">送金明細一覧ダウンロード</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import axios from "axios";
    axios.defaults.withCredentials = true;

    export default {
        name: 'List',
        data: function(){
            return {
                build_id    : null,
                from_year   : null,
                from_month  : null,
                to_year     : null,
                to_month    : null,
                bank_id     : null,

                builds  : [],
                years   : [],
                months  : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                banks   : [],

                axios_header    : {Authorization : "Bearer: "+this.$store.state.auth.token}
            };
        },

        //読み込み時
        created: function(){
            // 物件名のリスト取得
            axios.get("https://fconsult.xsrv.jp/api/owner/builds", {
                    headers : this.axios_header
                })
                .then((res)=>{
                    for (var i in res.data) {
                        this.builds.push({
                            'id'    : res.data[i].id,
                            'label' : res.data[i].name
                        });
                    }
                 })
                .catch((error)=>console.log(error));

            // 年のリスト取得
            axios.get("https://fconsult.xsrv.jp/api/owner/year_range", {
                headers: this.axios_header
            })
                .then((res)=>{
                    for(let cnt = res.data.premier; cnt>=res.data.latest; cnt--){
                        this.years.push(cnt);
                    }
                })
                .catch((error)=>console.log(error));

            //金融機関のリスト取得
            axios.get("https://fconsult.xsrv.jp/api/owner/banks", {
                    headers: this.axios_header
                })
                .then((res) => {
                    for(let i in res.data){
                        this.banks.push({
                            'bank_id'   : res.data[i].id,
                            'bank_name' : res.data[i].bank_name,
                        });
                    }
                })
                .catch((error)=>console.log(error));
        },

        //アクション
        methods: {
            onSubmit : function(){
                //送金明細情報取得
                const query_strings = '?build_id='+this.build_id+'&bank_id='+this.bank_id+'&from_year='+this.from_year+'&from_month='+this.from_month+'&to_year='+this.to_year+'&to_month='+this.to_month;
                axios.get("/pdf/list.php"+query_strings, {
                        responseType : "blob",
                        headers : this.axios_header
                    })
                    .then((res) => {
                        if( res.data.size==5 ){
                            alert('データが存在しません。');
                            return;
                        }
                        let userAgent = window.navigator.userAgent.toLowerCase();
                        let file = new Blob([res.data], {type: 'application/pdf'});
                        let url = URL.createObjectURL(file);

                        if(userAgent.indexOf('trident')!=-1 || userAgent.indexOf('msie')!=-1 || userAgent.indexOf('edge')!=-1){
                            console.log('ie');
                            window.navigator.msSaveOrOpenBlob(file, "送金明細.pdf");
                        }else{
                            const a = document.createElement("a");
                            document.body.appendChild(a);
                            a.download = '送金明細一覧.pdf';
                            a.href = url;
                            a.click();
                            a.remove();
                        }
                        return;
                     })
                    .catch((error) => console.log(error));
            },
        }
    }
</script>


<style scoped>
    .search-form .search_title{
        display: flex;
        align-items: center;
        border-left:5px solid #e5e5e5;
        padding:0 1em;
        font-weight: 900;
    }
</style>
