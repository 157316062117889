/* jshint unused: false */

export default {
  namespaced: true,
  state: {
    unauthorized: '',
    error:''
  },
  mutations: {
    create (state, message) {
      state.error = message
    },
    destroy (state) {
      state.error = ''
    },
  },
  actions: {
    create ({ commit }, message) {
      commit('create', message.error)
    },
    destroy ({ commit }) {
      commit('destroy')
    }
  }
}
