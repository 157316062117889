import { render, staticRenderFns } from "./App.vue?vue&type=template&id=65aaa794&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./App.vue?vue&type=custom&index=0&blockType=link&href=https%3A%2F%2Ffonts.googleapis.com%2Fcss%3Ffamily%3DMontserrat%26display%3Dswap&rel=stylesheet"
if (typeof block0 === 'function') block0(component)

export default component.exports