<template>
    <div id="password" class="customerPage">
        <h1>
            <div>
                基本情報
                <span>PROFILE</span>
            </div>
        </h1>

        <div class="contents">
            <div class="block">
                <h2>オーナー情報</h2>
                <table class="commonTbl">
                    <tr>
                        <th>ID</th>
                        <td>{{ tintaikakumei_id }}</td>
                    </tr>

                    <tr>
                        <th>名前</th>
                        <td>{{ name }}</td>
                    </tr>
                </table>

                <h2 style="margin-top:100px">メールアドレス変更</h2>
                <table class="commonTbl setting_table">
                    <tr>
                        <th class="password_title">
                            新しいメールアドレス
                            <div class="attension">{{ email_attension }}</div><div class="message">{{ email_message }}</div>
                        </th>
                        <td class="formWrap password_box">
                            <input type="text" value="" v-model="email">
                            <button type="button" class="btn loginBtn" @click="onSubmitEmail()">変更</button>
                        </td>
                    </tr>
                </table>

                <h2 style="margin-top:100px">パスワード変更</h2>
                <table class="commonTbl setting_table">
                    <tr>
                        <th class="password_title">
                            新しいパスワード (12文字以上)
                            <div class="attension">{{ password_attension }}</div><div class="message">{{ password_message }}</div>
                        </th>
                        <td class="formWrap password_box">
                            <input type="text" value="" v-model="password">
                            <button type="button" class="btn loginBtn" @click="onSubmitPassword()">変更</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>


<script>
    import axios from "axios";
    axios.defaults.withCredentials = true;

    export default {
        name: 'Passowrd',
        data: function(){
            return {
                tintaikakumei_id    : null,
                name                : null,
                email               : null,
                email_attension     : '',
                email_message       : '',
                password            : '',
                password_attension  : '',
                password_message    : '',

                axios_header    : {Authorization : "Bearer: "+this.$store.state.auth.token}
            };
        },

        //読み込み時
        created: function(){
            // オーナー情報の読み込み
            axios.get("https://fconsult.xsrv.jp/api/owner/profile", {
            //axios.get("https://localhost/api/owner/profile", {
                    headers : this.axios_header
                })
                .then((res)=>{
                    this.tintaikakumei_id   = res.data.id;
                    this.name               = res.data.name;
                    this.email              = res.data.email;
                 })
                .catch((error)=>console.log(error));
        },

        //アクション
        methods: {
            onSubmitPassword : function(){
                this.password_attension = '';
                this.password_message = '';
                if( this.password=='' || this.password.length<12 ){
                    this.password_attension = 'パスワードは12文字以上にする必要があります。';
                    return;
                }
                //パスワード変更
                axios.post("https://fconsult.xsrv.jp/api/owner/password",
                    {
                        password: this.password
                    },
                    {
                        headers : this.axios_header
                    })
                    .then(() => {
                        this.password_message = 'パスワードを変更しました！';
                        this.password = '';
                     })
                    .catch((error) => {
                        console.log(error);
                        this.password_attension = 'パスワードの変更に失敗しました';
                    });
            },

            onSubmitEmail : function(){
                this.email_attension = '';
                this.email_message = '';
                //メールアドレス変更
                axios.post("https://fconsult.xsrv.jp/api/owner/email",
                //axios.post("https://localhost/api/owner/email",
                    {
                        email: this.email
                    },
                    {
                        headers : this.axios_header
                    })
                    .then(() => {
                        this.email_message = 'メールアドレスを変更しました！';
                     })
                    .catch((error) => {
                        this.email_attension = 'メールアドレスを変更に失敗しました';
                        console.log(error);
                    });
            },
        }
    }
</script>
