import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
//import Reset from '../views/Reset.vue'
//import Setting from '../views/Setting.vue'
import Logout from '../views/Logout.vue'
import Dashboard from '../views/Dashboard.vue'
import Download from '../views/Download.vue'
import List from '../views/List.vue'
import News from '../views/News.vue'
import Password from '../views/Password.vue'
import PasswordResetMail from '../views/PasswordResetMail.vue'
import PasswordReset from '../views/PasswordReset.vue'
import AdminLogin from '../views/AdminLogin.vue'
import Store from '@/store/index.js'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/download',
    name: 'Download',
    component: Download
  },
  {
    path: '/list',
    name: 'List',
    component: List
  },
  {
    path: '/news/:id',
    name: 'News',
    component: News
  },
  {
    path: '/profile',
    name: 'Password',
    component: Password
  },
  {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        isPublic: true
      }
  },
  {
      path: '/password_reset_mail',
      name: 'password_reset_mail',
      component: PasswordResetMail,
      meta: {
        isPublic: true
      }
  },
  {
      path: '/password_reset/:token',
      name: 'password_reset',
      component: PasswordReset,
      meta: {
        isPublic: true
      }
  },
  /*
  {
    path: '/reset',
    name: 'reset',
    component: Reset,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/setting',
    name: 'setting',
    component: Setting,
    meta: {
      isPublic: true
    }
  },
  */
  {
      path: '/logout',
      name: 'logout',
      component: Logout,
      meta: {
        isPublic: true
      }
  },


  {
      path: '/fconsult/login',
      name: 'admin_login',
      component: AdminLogin,
      meta: {
        isPublic: true
      }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(page => page.meta.isPublic) || Store.state.auth.token) {
    next()
  } else {
    next('/login')
  }
})

export default router
