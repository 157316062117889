<template>
  <div id="download" class="customerPage">
      <h1><div>送金明細ダウンロード<span>DOCUMENT</span></div></h1>
      <div class="contents">
          <div class="block">
            <searchform-component></searchform-component>
          </div>
      </div>
  </div>
</template>

<script>

import searchformComponent from '@/components/SearchformComponent'

export default {
  components: { searchformComponent },
  template: '<searchform-component></searchform-component>'
}

</script>
