<template>
    <div id="login">
        <div class="flex">
            <div class="loginForm">
                <div>
                    <div id="logo"><router-link to="/"><img src="../assets/img/common/logo.png" alt="SOSOGoo! HOLDINGS"></router-link></div>

                    <div class="container">
                        <h2>Password Reset</h2>

                        <div id="login_err_message" v-show="errorMessage"><p>{{ errorMessage }}</p></div>

                        <div class="formWrap">
                            <div class="formItem">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <input v-model="email" type="text" value="" placeholder="メールアドレス" name="email">
                                    <p class="validate-err" v-show="errors[0]">{{ errors[0] }}</p>
                                </ValidationProvider>
                            </div>

                            <div class="formItem">
                              <ValidationProvider rules="required" v-slot="{ errors }">
                                  <input v-model="password" type="password" placeholder="新しいパスワード" name="password">
                                  <p class="validate-err" v-show="errors[0]">{{ errors[0] }}</p>
                              </ValidationProvider>
                            </div>

                            <div class="formItem">
                              <ValidationProvider rules="required" v-slot="{ errors }">
                                  <input v-model="password_confirmation" type="password" placeholder="新しいパスワード（確認用）" name="password_confirmation">
                                  <p class="validate-err" v-show="errors[0]">{{ errors[0] }}</p>
                              </ValidationProvider>
                            </div>

                            <div class="submit_btn">
                                <button type="button" class="btn loginBtn" @click="login()">メールを送信する</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div></div>
    </div>
</template>



<script>
// ajax用ライブラリ
import axios from "axios";
axios.defaults.withCredentials = true;

export default {
  data(){
      return{
          'email'               : '',
          'password'                : '',
          'password_confirmation'   : '',
      }
  },


  methods: {
    login () {
        if( this.password=='' || this.password.length<12 ){
            this.$store.state.message.error = 'パスワードは12文字以上にする必要があります。';
            return;
        }

        axios.post("https://fconsult.xsrv.jp/api/owner/password_reset", {
        //axios.post("https://localhost/api/owner/password_reset", {
            email                   : this.email,
            password                : this.password,
            password_confirmation   : this.password_confirmation,
            token                   : this.$route.params['token'],
        })
        .then((res) => {
            if( res.data==='error' ){
                this.$store.state.message.error = 'パスワードの更新に失敗しました。';
            }

            if( res.data==='success' ){
                alert('パスワードを更新しました。');
                this.$router.push('/login',()=>{});
            }
        })
        .catch((error) => console.log(error));
    }
  },


  computed: {
    isValidated () {
      return Object.keys(this.fields).every(k => this.fields[k].validated) &&
        Object.keys(this.fields).every(k => this.fields[k].valid)
    },

    errorMessage () {
      return this.$store.state.message.error
    }
  },


  created: function () {
    // already logined
    if (this.$store.state.auth.token) {
      this.$router.push('/dashboard',()=>{})
    }
  },
}
</script>
