<template>
  <div id="dashboard" class="customerPage">
      <h1><div>ダッシュボード<span>DASHBOARD</span></div></h1>
      <div class="contents">


        <div id="news" class="block">
            <h2>お知らせ</h2>
            <div class="news-list-wrapper">

              <div class="news-list" v-for="value in news" v-bind:key="value.id">
                  <div class="flex">
                      <p class="date">{{ value.posted_at | moment }}</p>
                      <p class="news_title">
                         <router-link :to="{name: 'News', params: {id: value.id}}">{{ value.title }}</router-link>
                      </p>
                  </div>
              </div>

            </div>
          </div>

          <div class="block">
            <h2>収入推移グラフ</h2>
          </div>

          <div id="search">
          <div class="search-form formWrap">
              <div class="flex">
                  <div class="flex" style="margin-right:2em;flex-wrap: nowrap;margin-bottom: 1em">
                      <div class="search_title">物件名</div>

                      <div class="">
                          <select name="build_id" id="build_id" v-model="build_id">
                            <option value="null" selected>全て</option>
                            <option
                              v-for="value in builds"
                              v-bind:key="value.id"
                              v-bind:value="value.id"
                            >
                              {{ value.label }}
                            </option>
                          </select>
                      </div>
                  </div>

                  <div class="flex" style="margin-bottom: 1em">
                      <div class="search_title">年</div>

                      <div class="">
                          <select name="year" id="year" v-model="year">
                            <option
                              v-for="(year, index) in years"
                              v-bind:key="year"
                              v-bind:value="year"
                              v-bind:selected="index===0"
                            >
                              {{ year }}
                            </option>
                          </select>
                          年
                      </div>
                  </div>
              </div>

            <div class="submit_btn">
              <button type="button" class="btn loginBtn" @click="onSubmit()">
                検索する
              </button>
            </div>
          </div>
          </div>
          <br>
          <chart :chartData="chartData" :chartTitle="chartTitle"></chart>
      </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
axios.defaults.withCredentials = false;
import Vue from 'vue/dist/vue.esm.js';
import Chart from './Chart';

export default {
    name: 'Dashboard',
    data() {
        //グラフ情報取得
        let graph = {
                total       : [],
                deduction   : [],
                income      : [],
            };
        let query_strings = '?build_id='+this.build_id+'&year='+(new Date()).getFullYear();
        axios.get("https://fconsult.xsrv.jp/api/owner/graph"+query_strings, {
            headers: {
                Authorization: "Bearer: "+this.$store.state.auth.token
            }
        })
        .then((res) => {
            for (let i = 1; i<=12; i++) {
                graph.total[i-1]        = res.data.total[i];
                graph.deduction[i-1]    = res.data.deduction[i];
                graph.income[i-1]       = res.data.income[i];
            }

            this.chartData = {
                datasets: [
                    {
                        label: '控除額',
                        lineTension: 0,
                        data: graph.deduction,
                        backgroundColor: 'rgba(199,21,133,0.5)',
                        borderColor: 'rgba(199,21,133,1)',
                        borderWidth: 2
                    },
                    {
                        label: '送金額',
                        lineTension: 0,
                        data: graph.total,
                        backgroundColor: 'rgba(199,199,80,0.5)',
                        borderColor: 'rgba(199,199,80,1)',
                        borderWidth: 2
                    },
                    {
                        label: '収入額',
                        lineTension: 0,
                        data: graph.income,
                        backgroundColor: 'rgba(0,172,154,0.5)',
                        borderColor: 'rgba(0,172,154,1)',
                        borderWidth: 2
                    }
                ],
            };
         })
        .catch((error) => console.log(error));

        return {
            build_id: null,
            year:(new Date()).getFullYear(),
            builds: [],
            years: [],
            news: [],
            chartTitle: (new Date()).getFullYear()+'年',
            chartData: {
                labels: ['1月', '2月', '3月', '4月', '5月', '6月','7月','8月','9月','10月','11月','12月'],
                datasets: [
                    {
                        label: '控除額',
                        lineTension: 0,
                        data: graph.deduction,
                        backgroundColor: 'rgba(199,21,133,0.5)',
                        borderColor: 'rgba(199,21,133,1)',
                        borderWidth: 2
                    },
                    {
                        label: '送金額',
                        lineTension: 0,
                        data: graph.total,
                        backgroundColor: 'rgba(199,199,80,0.5)',
                        borderColor: 'rgba(199,199,80,1)',
                        borderWidth: 2
                    },
                    {
                        label: '収入額',
                        lineTension: 0,
                        data: graph.income,
                        backgroundColor: 'rgba(0,172,154,0.5)',
                        borderColor: 'rgba(0,172,154,1)',
                        borderWidth: 2
                    }
                ],
            },
        }
    },

    created: function() {
        //ニュース一覧作るよう
        axios.get("https://fconsult.xsrv.jp/api/news/list", {
            headers: {
                Authorization: "Bearer: "+this.$store.state.auth.token
            }
        })
        .then((res) => {
            this.news = res.data;
        });

        // 物件名のリストを作るよう
        axios.get("https://fconsult.xsrv.jp/api/owner/builds", {
            headers: {
                Authorization: "Bearer: "+this.$store.state.auth.token
            }
        })
        .then((res) => {
            for (var i in res.data) {
                this.builds.push({
                    'id': res.data[i].id,
                    'label': res.data[i].name
                });
            }
        })
        .catch((error) => console.log(error));

        // 年セレクトボックス生成用
        axios.get("https://fconsult.xsrv.jp/api/owner/year_range", {
                headers: {
                    Authorization: "Bearer: "+this.$store.state.auth.token
                }
            })
            .then((res) => {
                for(let cnt = res.data.premier; cnt>=res.data.latest; cnt--){
                    this.years.push(cnt);
                }
            })
            .catch((error) => console.log(error));
    },

    methods: {
        onSubmit() {
            let ComponentClass = Vue.extend(Chart);
            let instance = new ComponentClass();
            let graph = {
                    total       : [],
                    deduction   : [],
                    income      : [],
                };

            instance.$mount();

            //グラフ情報取得
            let query_strings = '?build_id='+this.build_id+'&year='+this.year;

            axios.get("https://fconsult.xsrv.jp/api/owner/graph"+query_strings, {
                headers: {
                    Authorization: "Bearer: "+this.$store.state.auth.token
                }
            })
            .then((res) => {
                this.data = res.data;

                this.chartTitle = this.year+'年';

                for (let i = 1; i<=12; i++) {
                    graph.total[i-1]        = res.data.total[i];
                    graph.deduction[i-1]    = res.data.deduction[i];
                    graph.income[i-1]       = res.data.income[i];
                }

                //グラフの書き換え
                this.chartData = {
                    labels: ['1月', '2月', '3月', '4月', '5月', '6月','7月','8月','9月','10月','11月','12月'],
                    datasets: [
                        {
                            label: '控除額',
                            lineTension: 0,
                            data: graph.deduction,
                            backgroundColor: 'rgba(199,21,133,0.5)',
                            borderColor: 'rgba(199,21,133,1)',
                            borderWidth: 2
                        },
                        {
                            label: '送金額',
                            lineTension: 0,
                            data: graph.total,
                            backgroundColor: 'rgba(199,199,80,0.5)',
                            borderColor: 'rgba(199,199,80,1)',
                            borderWidth: 2
                        },
                        {
                            label: '収入額',
                            lineTension: 0,
                            data: graph.income,
                            backgroundColor: 'rgba(0,172,154,0.5)',
                            borderColor: 'rgba(0,172,154,1)',
                            borderWidth: 2
                        }
                    ]
                };
            })
            .catch((error) => console.log(error));
        }
    },

    components: {
        Chart,
    },

    filters: {
      moment: function(date) {
        return moment(date).format("YYYY/MM/DD");
      }
    },
}
</script>

<style scoped>
    .search-form .search_title{
        display: flex;
        align-items: center;
        border-left:5px solid #e5e5e5;
        padding:0 1em;
        font-weight: 900;
    }
</style>
