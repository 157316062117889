<template>
  <div id="logout" class="customerPage">
  </div>
</template>

<script>
// ajax用ライブラリ
import axios from "axios";
axios.defaults.withCredentials = true;
// @ is an alias to /src

export default {
  name: 'Logout',
  created:function(){
    /**
     *  ログアウトAPIを叩く
     */
    axios.get('https://fconsult.xsrv.jp/api/owner/logout',{
        headers: {
          Authorization: "Bearer: "+this.$store.state.auth.token
        }
    })
    .then(()=>{
        this.$store.dispatch(
          'auth/destroy',
          {},
          { root:true }
        );

        axios.get("/manage/logout");

        this.$router.push('/login');
    })
    .catch((error) => {
        this.$store.dispatch(
          'auth/destroy',
          {},
          { root:true }
        );
        console.log(error);
        this.$router.push('/login');
    });
  }
}
</script>
