<script>
import { Line,mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Line,
  name: 'chart',
  mixins: [reactiveProp],
  props:['chartData', 'chartTitle'],

  data () {
    return {
      options: {
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.chartTitle,
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 50000,
            }
          }]
      },
      }
    }
  },

  mounted () {
        this.$refs.canvas.height = screen.height / (screen.height>screen.width ? 2 : 6);
        this.renderChart(this.chartData, this.options);
    },

    watch: {
         chartTitle () {
             this.renderChart(this.chartData, {
               scales: {
                 xAxes: [{
                   scaleLabel: {
                     display: true,
                     labelString: this.chartTitle,
                   }
                 }],
                 yAxes: [{
                   ticks: {
                     beginAtZero: true,
                     stepSize: 50000,
                   }
                 }]
             },
             });
         }
       }
}
</script>
