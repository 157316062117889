<template>
    <div id="download" class="customerPage">
        <h1>
            <div>
                お知らせ
                <span>News</span>
            </div>
        </h1>

        <div class="contents">
            <div class="block">
                <div id="news" class="block">
                    <h2>{{ title }}</h2>
                    <div class="news_posted_at">{{ posted_at }}</div>

                    <div class="news_content" v-html="content"></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import axios from "axios";
    axios.defaults.withCredentials = true;

    export default {
        name: 'List',
        data: function(){
            return {
                title       : null,
                posted_at   : null,
                content     : null,
                axios_header    : {Authorization : "Bearer: "+this.$store.state.auth.token}
            };
        },

        //読み込み時
        created: function(){
            // 物件名のリスト取得
            axios.get("https://fconsult.xsrv.jp/api/news/"+this.$route.params['id'], {
                    headers : this.axios_header
                })
                .then((res)=>{
                    this.title = res.data.title;
                    this.posted_at = res.data.posted_at;
                    this.content = res.data.content;
                 })
                .catch((error)=>console.log(error));
        },
    }
</script>
